enum RouteConstants {
  HOME = '/',
  SELECT_STORE = '/selectStore',
  HISTORY = '/history',
  ADDED_TO_CART = '/history/addedToCart',
  CARES_SUPPORT_TIERS = '/cares',
  DIGITAL_ORDERING = '/digitalOrdering',
  MENU = '/menu',
  POS = '/pos',
  KPS = '/kps',
  VIEWPOINT = '/viewPoint',
  CFA_DELIVERY = '/delivery',
  THIRD_PARTY_DELIVERY = '/thirdPartyDelivery',
  STORE_LISTING = '/storeListing',
  MAINTENANCE_MODE = '/maintenanceMode',
  HELP = '/help',
  GUIDE = '/guide',
  SYSTEM_TOOLS = '/systemTools',
  TROUBLESHOOT = '/systemTools/troubleshoot',
}

export default RouteConstants;
